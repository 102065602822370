@font-face {
  font-family: 'sah-ds-icons';
  src: url('https://static.spotahome.com/fonts/ds-icons/v14/sah-ds-icons.eot');
  src: url('https://static.spotahome.com/fonts/ds-icons/v14/sah-ds-icons.eot#iefix') format('embedded-opentype'),
    url('https://static.spotahome.com/fonts/ds-icons/v14/sah-ds-icons.woff2') format('woff2'),
    url('https://static.spotahome.com/fonts/ds-icons/v14/sah-ds-icons.woff') format('woff'),
    url('https://static.spotahome.com/fonts/ds-icons/v14/sah-ds-icons.ttf') format('truetype'),
    url('https://static.spotahome.com/fonts/ds-icons/v14/sah-ds-icons.svg#sah-ds-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='ds-icon-']:before,
[class*=' ds-icon-']:before {
  font-family: 'sah-ds-icons';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ds-icon-block:before { content: '\e800'; } /* '' */
.ds-icon-add_box:before { content: '\e801'; } /* '' */
.ds-icon-booking_request:before { content: '\e802'; } /* '' */
.ds-icon-clock:before { content: '\e803'; } /* '' */
.ds-icon-more_horiz_circle_v2:before { content: '\e804'; } /* '' */
.ds-icon-savings:before { content: '\e805'; } /* '' */
.ds-icon-encrypted:before { content: '\e806'; } /* '' */
.ds-icon-faster:before { content: '\e807'; } /* '' */
.ds-icon-cancel:before { content: '\e808'; } /* '' */
.ds-icon-check_circle_transparent:before { content: '\e809'; } /* '' */
.ds-icon-air_conditioning:before { content: '\e80a'; } /* '' */
.ds-icon-area_m2:before { content: '\e80b'; } /* '' */
.ds-icon-arrow_down_left:before { content: '\e80c'; } /* '' */
.ds-icon-arrow_down_right:before { content: '\e80d'; } /* '' */
.ds-icon-accessible:before { content: '\e80e'; } /* '' */
.ds-icon-arrow_down:before { content: '\e80f'; } /* '' */
.ds-icon-arrow_full_bottom:before { content: '\e810'; } /* '' */
.ds-icon-arrow_full_left:before { content: '\e811'; } /* '' */
.ds-icon-arrow_full_right:before { content: '\e812'; } /* '' */
.ds-icon-arrow_full_top:before { content: '\e813'; } /* '' */
.ds-icon-arrow_left:before { content: '\e814'; } /* '' */
.ds-icon-arrow_up_right:before { content: '\e815'; } /* '' */
.ds-icon-arrow_up:before { content: '\e816'; } /* '' */
.ds-icon-balcony_terrace:before { content: '\e817'; } /* '' */
.ds-icon-bank_court_house:before { content: '\e818'; } /* '' */
.ds-icon-bolt_energy:before { content: '\e819'; } /* '' */
.ds-icon-calendar_available:before { content: '\e81a'; } /* '' */
.ds-icon-calendar_max:before { content: '\e81b'; } /* '' */
.ds-icon-calendar_min:before { content: '\e81c'; } /* '' */
.ds-icon-calendar_month:before { content: '\e81d'; } /* '' */
.ds-icon-central_water_heating:before { content: '\e81e'; } /* '' */
.ds-icon-chat:before { content: '\e81f'; } /* '' */
.ds-icon-check_box:before { content: '\e820'; } /* '' */
.ds-icon-check_circle:before { content: '\e821'; } /* '' */
.ds-icon-delete_bin:before { content: '\e822'; } /* '' */
.ds-icon-desk_table:before { content: '\e823'; } /* '' */
.ds-icon-device_landscape:before { content: '\e824'; } /* '' */
.ds-icon-device_portrait:before { content: '\e825'; } /* '' */
.ds-icon-diamond:before { content: '\e826'; } /* '' */
.ds-icon-dishwasher:before { content: '\e827'; } /* '' */
.ds-icon-double_bed:before { content: '\e828'; } /* '' */
.ds-icon-done_check:before { content: '\e829'; } /* '' */
.ds-icon-dresser:before { content: '\e82a'; } /* '' */
.ds-icon-dryer:before { content: '\e82b'; } /* '' */
.ds-icon-elevator:before { content: '\e82c'; } /* '' */
.ds-icon-equipped_kitchen:before { content: '\e82d'; } /* '' */
.ds-icon-exit_to_app:before { content: '\e82e'; } /* '' */
.ds-icon-exterior_picture:before { content: '\e82f'; } /* '' */
.ds-icon-fav_line:before { content: '\e830'; } /* '' */
.ds-icon-fav_filled:before { content: '\e831'; } /* '' */
.ds-icon-favs_plus:before { content: '\e832'; } /* '' */
.ds-icon-favs_minus:before { content: '\e833'; } /* '' */
.ds-icon-filters_settings:before { content: '\e834'; } /* '' */
.ds-icon-flag:before { content: '\e835'; } /* '' */
.ds-icon-floor_1:before { content: '\e836'; } /* '' */
.ds-icon-floor_2:before { content: '\e837'; } /* '' */
.ds-icon-floor_3:before { content: '\e838'; } /* '' */
.ds-icon-floor_4:before { content: '\e839'; } /* '' */
.ds-icon-floor_5:before { content: '\e83a'; } /* '' */
.ds-icon-floor_6:before { content: '\e83b'; } /* '' */
.ds-icon-floor_8:before { content: '\e83c'; } /* '' */
.ds-icon-floor_7:before { content: '\e83d'; } /* '' */
.ds-icon-floor_9:before { content: '\e83e'; } /* '' */
.ds-icon-floor_10:before { content: '\e83f'; } /* '' */
.ds-icon-floor_n:before { content: '\e840'; } /* '' */
.ds-icon-floor_plan:before { content: '\e841'; } /* '' */
.ds-icon-furnished_plus:before { content: '\e842'; } /* '' */
.ds-icon-furnished:before { content: '\e843'; } /* '' */
.ds-icon-grid_view:before { content: '\e844'; } /* '' */
.ds-icon-heating:before { content: '\e845'; } /* '' */
.ds-icon-help_circle:before { content: '\e846'; } /* '' */
.ds-icon-home:before { content: '\e847'; } /* '' */
.ds-icon-id:before { content: '\e848'; } /* '' */
.ds-icon-janitor_reception:before { content: '\e849'; } /* '' */
.ds-icon-key_locker:before { content: '\e84a'; } /* '' */
.ds-icon-language:before { content: '\e84b'; } /* '' */
.ds-icon-location_on:before { content: '\e84c'; } /* '' */
.ds-icon-lock_closed:before { content: '\e84d'; } /* '' */
.ds-icon-lock_open:before { content: '\e84e'; } /* '' */
.ds-icon-map:before { content: '\e84f'; } /* '' */
.ds-icon-menu:before { content: '\e850'; } /* '' */
.ds-icon-microwave:before { content: '\e851'; } /* '' */
.ds-icon-more_horiz:before { content: '\e852'; } /* '' */
.ds-icon-open_in_new:before { content: '\e853'; } /* '' */
.ds-icon-oven:before { content: '\e854'; } /* '' */
.ds-icon-perfect_for_couples:before { content: '\e855'; } /* '' */
.ds-icon-package:before { content: '\e856'; } /* '' */
.ds-icon-perfect_for_professionals:before { content: '\e857'; } /* '' */
.ds-icon-perfect_for_students:before { content: '\e858'; } /* '' */
.ds-icon-pets_allowed:before { content: '\e859'; } /* '' */
.ds-icon-pets_not_allowed:before { content: '\e85a'; } /* '' */
.ds-icon-photo_camera:before { content: '\e85b'; } /* '' */
.ds-icon-private_landlord:before { content: '\e85c'; } /* '' */
.ds-icon-private_bathroom:before { content: '\e85d'; } /* '' */
.ds-icon-private_parking:before { content: '\e85e'; } /* '' */
.ds-icon-professional_landlord:before { content: '\e85f'; } /* '' */
.ds-icon-proof_of_income:before { content: '\e860'; } /* '' */
.ds-icon-security_box:before { content: '\e861'; } /* '' */
.ds-icon-settings:before { content: '\e862'; } /* '' */
.ds-icon-shelving:before { content: '\e863'; } /* '' */
.ds-icon-single_bed:before { content: '\e864'; } /* '' */
.ds-icon-smoking_allowed:before { content: '\e865'; } /* '' */
.ds-icon-smoking_not_allowed:before { content: '\e866'; } /* '' */
.ds-icon-stairs:before { content: '\e867'; } /* '' */
.ds-icon-stats_figures:before { content: '\e868'; } /* '' */
.ds-icon-support_help:before { content: '\e869'; } /* '' */
.ds-icon-swimming_pool:before { content: '\e86a'; } /* '' */
.ds-icon-tv:before { content: '\e86b'; } /* '' */
.ds-icon-video_cam:before { content: '\e86c'; } /* '' */
.ds-icon-washing_machine:before { content: '\e86d'; } /* '' */
.ds-icon-water_drop:before { content: '\e86e'; } /* '' */
.ds-icon-wifi_off:before { content: '\e86f'; } /* '' */
.ds-icon-wifi:before { content: '\e870'; } /* '' */
.ds-icon-window:before { content: '\e871'; } /* '' */
.ds-icon-zoom_close:before { content: '\e872'; } /* '' */
.ds-icon-zoom_in:before { content: '\e873'; } /* '' */
.ds-icon-zoom_out:before { content: '\e874'; } /* '' */
.ds-icon-shared:before { content: '\e875'; } /* '' */
.ds-icon-check_shield:before { content: '\e876'; } /* '' */
.ds-icon-twin_bed:before { content: '\e877'; } /* '' */
.ds-icon-bunk_bed:before { content: '\e878'; } /* '' */
.ds-icon-bed_linen:before { content: '\e879'; } /* '' */
.ds-icon-cleaning_service:before { content: '\e87a'; } /* '' */
.ds-icon-meal_service:before { content: '\e87b'; } /* '' */
.ds-icon-electricity_bill:before { content: '\e87c'; } /* '' */
.ds-icon-gas_bill:before { content: '\e87d'; } /* '' */
.ds-icon-interior_house:before { content: '\e87e'; } /* '' */
.ds-icon-nest_clock_farsight_analog:before { content: '\e87f'; } /* '' */
.ds-icon-progress_activity:before { content: '\e880'; } /* '' */
.ds-icon-warning:before { content: '\e881'; } /* '' */
.ds-icon-discover_tune:before { content: '\e882'; } /* '' */
.ds-icon-print:before { content: '\e883'; } /* '' */
.ds-icon-support_agent:before { content: '\e884'; } /* '' */
.ds-icon-cottage:before { content: '\e885'; } /* '' */
.ds-icon-call:before { content: '\e886'; } /* '' */
.ds-icon-location_on-1:before { content: '\e887'; } /* '' */
.ds-icon-content_copy:before { content: '\e888'; } /* '' */
.ds-icon-eye-filled:before { content: '\e889'; } /* '' */
.ds-icon-flexible_cancellation:before { content: '\e88b'; } /* '' */
.ds-icon-credit-card:before { content: '\e88c'; } /* '' */
.ds-icon-content_copy_empty:before { content: '\e88e'; } /* '' */
.ds-icon-key_down:before { content: '\e891'; } /* '' */
.ds-icon-envelope:before { content: '\e892'; } /* '' */
.ds-icon-verified_card:before { content: '\e893'; } /* '' */
.ds-icon-person-filled:before { content: '\e894'; } /* '' */
.ds-icon-female:before { content: '\e895'; } /* '' */
.ds-icon-person:before { content: '\e896'; } /* '' */
.ds-icon-male:before { content: '\e897'; } /* '' */
.ds-icon-format_list_bulleted:before { content: '\e898'; } /* '' */
.ds-icon-happy_face:before { content: '\e8ef'; } /* '' */
.ds-icon-over_one_year_calendar:before { content: '\e8f0'; } /* '' */
.ds-icon-wardrobe:before { content: '\e900'; } /* '' */
.ds-icon-drawers:before { content: '\e901'; } /* '' */
